import React from 'react'

const ChartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 263.099 263.099"
      className="texticon"
    >
      <path d="M78.067 124.884c-6.708 0-12.167 5.458-12.167 12.166v49.667c0 6.709 5.458 12.167 12.167 12.167h15c6.708 0 12.167-5.458 12.167-12.167V137.05c0-6.708-5.458-12.166-12.167-12.166h-15zm15.167 61.833a.167.167 0 01-.167.167h-15a.167.167 0 01-.167-.167V137.05c0-.092.075-.167.167-.167h15c.092 0 .167.075.167.167v49.667zM139.067 97.55h-15c-6.708 0-12.167 5.458-12.167 12.167v77c0 6.709 5.458 12.167 12.167 12.167h15c6.708 0 12.167-5.458 12.167-12.167v-77c0-6.709-5.458-12.167-12.167-12.167zm.167 89.167a.167.167 0 01-.167.167h-15a.167.167 0 01-.167-.167v-77c0-.092.075-.167.167-.167h15c.092 0 .167.075.167.167v77zM170.067 64.217c-6.708 0-12.167 5.458-12.167 12.167v110.333c0 6.709 5.458 12.167 12.167 12.167h15c6.708 0 12.167-5.458 12.167-12.167V76.384c0-6.709-5.458-12.167-12.167-12.167h-15zm15.167 122.5a.167.167 0 01-.167.167h-15a.167.167 0 01-.167-.167V76.384c0-.092.075-.167.167-.167h15c.092 0 .167.075.167.167v110.333z" />
      <path d="M260.47 105.283a6 6 0 10-11.759 2.395c9.287 45.609-8.969 92.86-46.508 120.377-25.596 18.764-57.172 26.402-88.921 21.513-31.746-4.89-59.562-21.68-78.325-47.276-18.762-25.596-26.402-57.175-21.512-88.921s21.68-59.562 47.275-78.324c25.596-18.764 57.178-26.403 88.921-21.513 31.315 4.824 58.801 21.233 77.551 46.246l-7.599-1.464a5.999 5.999 0 10-2.269 11.783l22.281 4.292a6.002 6.002 0 007.027-4.757l4.292-22.281a5.999 5.999 0 00-4.757-7.026 5.998 5.998 0 00-7.026 4.757l-1.628 8.454C216.857 25.458 186.31 7.041 151.47 1.674 116.553-3.708 81.806 4.71 53.625 25.368 25.445 46.025 6.963 76.63 1.584 111.544c-5.378 34.914 3.037 69.661 23.694 97.842 20.658 28.181 51.263 46.663 86.176 52.041a135.05 135.05 0 0020.581 1.584c27.778 0 54.637-8.694 77.262-25.279 41.304-30.277 61.391-82.266 51.173-132.449z" />
    </svg>
  )
}

export default ChartIcon
