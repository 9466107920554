import React from 'react'

const SettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 458.317 458.317"
      className="texticon"
    >
      <path d="M446.185 179.159h-64.768a159.359 159.359 0 00-9.26-22.29l45.818-45.818c4.737-4.737 4.737-12.416 0-17.152L364.416 40.34c-4.737-4.737-12.416-4.737-17.152 0l-45.818 45.818a159.347 159.347 0 00-22.289-9.26V12.131c.001-6.699-5.429-12.129-12.128-12.129h-75.743c-6.698 0-12.129 5.43-12.129 12.128v64.768a159.289 159.289 0 00-22.29 9.26L111.05 40.341c-4.737-4.737-12.416-4.737-17.152 0L40.339 93.9c-4.737 4.736-4.737 12.416 0 17.152l45.817 45.817a159.239 159.239 0 00-9.26 22.29H12.129C5.43 179.159 0 184.59 0 191.288v75.743c0 6.698 5.43 12.128 12.129 12.128h64.768a159.409 159.409 0 009.26 22.29L40.34 347.266c-4.737 4.736-4.737 12.416 0 17.152l53.559 53.559c4.737 4.736 12.416 4.736 17.152 0l45.817-45.817a159.189 159.189 0 0022.29 9.26v64.768c0 6.698 5.43 12.128 12.129 12.128h75.743c6.698 0 12.129-5.43 12.129-12.128V381.42a159.226 159.226 0 0022.289-9.26l45.818 45.817c4.737 4.736 12.416 4.736 17.152 0l53.559-53.559c4.737-4.737 4.737-12.416 0-17.152l-45.817-45.817a159.347 159.347 0 009.26-22.289h64.768c6.698 0 12.129-5.43 12.129-12.128v-75.743c-.003-6.699-5.433-12.13-12.132-12.13zM229.157 289.542c-33.349 0-60.384-27.035-60.384-60.384s27.035-60.384 60.384-60.384 60.384 27.035 60.384 60.384-27.035 60.384-60.384 60.384z" />
    </svg>
  )
}

export default SettingsIcon
