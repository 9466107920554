import React from 'react'

const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="texticon"
    >
      <path d="M162.5 102.5c0-39.977 32.523-72.5 72.5-72.5s72.5 32.523 72.5 72.5V160h30v-57.5C337.5 45.981 291.519 0 235 0S132.5 45.981 132.5 102.5V160h30v-57.5zM77.5 190v280h315V190h-315zm211.594 172.881l-21.213 21.213L235 351.213l-32.881 32.881-21.213-21.213L213.787 330l-32.881-32.881 21.213-21.213L235 308.787l32.881-32.881 21.213 21.213L256.213 330l32.881 32.881z" />
    </svg>
  )
}

export default LockIcon
