import React from 'react'

interface Props {
  content?: string
  className?: string
}

const SteamButton = (props: Props) => {
  return (
    <a href="/api/auth/">
      <button
        className={`bg-green-700 text-white rounded p-1 pl-2 pr-2 block focus:outline-none ${props.className}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 58 58"
          height="30"
          width="35"
          className="texticon"
        >
          <path d="M50 26c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z" />
          <path d="M55.918 27.364A7.959 7.959 0 0058 22c0-4.411-3.589-8-8-8-3.661 0-6.748 2.475-7.695 5.837L38.108 31.03a6.434 6.434 0 00-2.721.781l-20.47-8.389A7.5 7.5 0 007.5 17C3.364 17 0 20.364 0 24.5S3.364 32 7.5 32a7.424 7.424 0 004.144-1.274l20.592 8.44c.006.034-.002.067.008.101A6.52 6.52 0 0038.5 44c3.584 0 6.5-2.916 6.5-6.5 0-.175-.013-.348-.026-.52l10.68-9.223a.98.98 0 00.264-.393zM50 16c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6c0-.531.076-1.044.206-1.535l.23-.614c.019-.051.014-.103.025-.155A6.009 6.009 0 0150 16zM6.241 26.351a2.003 2.003 0 01-1.093-2.608 2.006 2.006 0 012.61-1.093l26.918 11.032c.19.186.433.283.69.283l2.891 1.185c.495.203.881.586 1.087 1.079s.208 1.036.005 1.531a1.997 1.997 0 01-2.608 1.092l-30.5-12.501zM7.5 30C4.468 30 2 27.532 2 24.5S4.468 19 7.5 19c2.293 0 4.274 1.425 5.089 3.467l-4.073-1.669a4.01 4.01 0 00-5.219 2.185 4.007 4.007 0 002.185 5.218l3.725 1.527A5.485 5.485 0 017.5 30zm31 12a4.505 4.505 0 01-3.505-1.703l.988.405a3.987 3.987 0 003.062-.013 3.968 3.968 0 002.155-2.172 4.006 4.006 0 00-2.184-5.22l-.694-.285c.06-.001.118-.012.178-.012 2.481 0 4.5 2.019 4.5 4.5S40.981 42 38.5 42zm1.671-10.774l2.377-6.338c1.161 2.985 4.058 5.111 7.449 5.112l-5.582 4.821a6.518 6.518 0 00-4.244-3.595z" />
        </svg>
        {props.content ? props.content : 'Login'}
      </button>
    </a>
  )
}

export default SteamButton
